<template>
  <div class="user">
    <div class="tab_txt">
      <div class="storeCenter_item_top">
        <div class="storeCenter_item_top_left">
          <img :src="ginsenginfo.coverImage" alt="" />
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <h5 style="display: inline-block; margin: 0; font-size: 18px">
                {{ ginsenginfo.zcName }}
              </h5>
            </div>
            <div style="padding-top: 10px">
              <span>中国- {{ ginsenginfo.province }}-{{ ginsenginfo.city }}</span>
            </div>
            <p class="address">
                <span>{{ ginsenginfo.zcIntro }}</span>
                <!-- <span v-for="(o, index) in ginsenginfo.partnerList" :key="index">
                  <span  class="office">{{ o }}</span>
                </span> -->
            </p>
          </div>
        </div>
        <div class="storeCenter_item_top_right"></div>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="80px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label=" " label-width="0px" style="padding-top: 20px; margin-bottom: 0">
                <div class="input-with">
                  <div>
                    <el-input
                      placeholder="请输入企业名称"
                      v-model="queryInfo.queryConditions"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="search()">搜索</el-button>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <el-table
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="编号" align="center" prop="id"></el-table-column>
        <el-table-column label="logo" align="center">
          <template v-slot="{ row }">
            <img :src="row.companyLogo" width="42" height="42" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="企业名称" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.companyFullName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="法人" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.legalPerson }}</span>
          </template>
        </el-table-column>
       
        <el-table-column label="职位" prop="position" align="center"></el-table-column>

        <el-table-column label="联系电话" prop="companyPhone" align="center"></el-table-column>
        <el-table-column label="标签" prop="address" align="center">
            <template v-slot="{row}">
               
                <el-popover placement="right" width="230" trigger="hover">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                    style="color: #4E93FB;cursor: pointer;margin: 0px 10px; "
                     
                      v-for="(o, indexs) in row.labelName"
                      :key="indexs"
                      >#{{ o }}</span
                    >
                  </div>
                  <div slot="reference">
                    <div v-for="(el,index) in row.labelName" :key="index">
                    <span  v-if="index < 3" style="color: #4E93FB;cursor: pointer; ">#{{ el }}</span>
                </div>
                  </div>
                
                </el-popover>
            </template>
        </el-table-column>

        <el-table-column label="所在城市" prop="" align="center">
            <template v-slot="{ row }">
            <span>{{ row.province }}-{{ row.city }}</span>
          </template>
        </el-table-column>
        <el-table-column label="企业官网" prop="officialWebsite" align="center"></el-table-column>

       
       
        
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template v-slot="{ row }">
            <el-button style="color: #146aff" type="text" @click="infocompany(row)">详情 </el-button>
           
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { wisdomGinsengCompanyPage, getWisdomGinsengDetail } from '@/api/channel'

const defaultQueryInfo = Object.freeze({
  channelType: '',
  city: null,
  orderByParam: null,
  id:null,
  pageNum: 1,
  pageSize: 10,
  province: null,
  queryConditions: null
})
export default {
  name: 'ginsengcompany',
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  data() {
    return {
      ginsenginfo: {}, //智参中心单个数据
      listData: [], //关联企业
      queryInfo: { ...defaultQueryInfo }
    }
  },
  created() {
    this.selectPartnerById()
    this.search()
  },
  methods: {
    async selectPartnerById() {
      const res = await getWisdomGinsengDetail({ id: this.id })
      if (res.data.resultCode == 200) {
        this.ginsenginfo = res.data.data
      }
    },
    async search() {
        this.queryInfo.id=this.id
      this.loading = true
      const res = await wisdomGinsengCompanyPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      }
    },
    infocompany(row){
        this.$router.push({
          path: '/customer/addenterprise',
          query: {
            id: row.id,
            headTabActive:1
          }
        })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.office {
              border: 1px solid #FF7D18;
              color: #FF7D18;
              background: #fff2e8;
              display: inline-block;
              padding: 0px 5px;
              margin-right: 10px;
            }
.form_info {
  background: #ffffff;
  padding: 20px;
}

.tab_txt {
 
  padding: 0px;
}

.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: right;
  padding: 0px 10px;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.storeCenter_item_top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 14px;
  position: relative;
  background: #ffffff;
  padding: 16px 20px 10px 10px;
  .storeCenter_item_top_type {
    position: absolute;
    width: 93px;
    height: 34px;
    background: #ff7d18;
    color: #fff;
    text-align: center;
    line-height: 34px;
    border-radius: 10px 10px 10px 0px;
    top: 0px;
    left: 0px;
  }
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;

    img {
      width: 80px;
      height: 80px;
      border-radius: 2px;
      margin-right: 14px;
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .address {
        font-size: 14px;
      }
    }
  }
}

.new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

</style>
